import React from "react";
const FeatureCard = ({ image, content, isReversed = false }) => {
  return (
    <div
      className={`two-col-feature__card ${
        isReversed ? "md:flex-row-reverse" : ""
      }`}
    >
      <div className="two-col-feature__card__image">{image}</div>
      <div className="two-col-feature__card__content">{content}</div>
    </div>
  );
};
export default FeatureCard;
