import React from "react";

import feature1 from "../../images/service-reliability-management/feature1.svg";
import feature2 from "../../images/service-reliability-management/feature2.svg";

import icon1 from "../../images/service-reliability-management/errorTracking1.svg";
import icon2 from "../../images/service-reliability-management/errorTracking2.svg";
import icon3 from "../../images/service-reliability-management/errorTracking3.svg";

import icon4 from "../../images/service-reliability-management/icon1.svg";
import icon5 from "../../images/service-reliability-management/icon2.svg";
import icon6 from "../../images/service-reliability-management/icon3.svg";
import icon7 from "../../images/service-reliability-management/icon4.svg";
import icon8 from "../../images/service-reliability-management/icon5.svg";
import icon9 from "../../images/service-reliability-management/icon6.svg";

import card1 from "../../images/service-reliability-management/card1.svg";
import card2 from "../../images/service-reliability-management/card2.svg";
import card3 from "../../images/service-reliability-management/card3.svg";
import card4 from "../../images/service-reliability-management/card4.svg";
import card5 from "../../images/service-reliability-management/card5.svg";

export const SRMFeatures = [
  {
    id: 1,
    image: (
      <img
        src={feature1}
        alt="Continuous reliability improvement"
        loading="lazy"
        width={562}
      />
    ),
    content: (
      <>
        <h2>信頼性の継続的な改善</h2>
        <p>
          パイプラインの適切な段階で適切なチェックを自動的に実行（シフトレフトリライアビリティー）し、信頼性の高いソフトウェアの迅速なデリバリーを実現できます。
        </p>
        <h2>SLO重視のパイプラインガバナンス</h2>
        <p>
          信頼性ガードレールにより、優秀な開発者が信頼性の高いソフトウェアを高速でデリバリーし続けられるようにするだけでなく、他の開発者や機密性の高いプロジェクトのためにガードレールを設置できます。
        </p>
      </>
    ),
  },
  {
    id: 2,
    isReversed: true,
    image: (
      <img
        src={feature2}
        alt="Joint SLO Management"
        loading="lazy"
        width={562}
      />
    ),
    content: (
      <>
        <h2>共同SLO管理</h2>
        <div>
          <span className="block mb-6 md:mb-12">
            開発チーム、デプロイチーム、信頼性チームが一緒にSLOを定義することで、全員がメリットを得ることができます。エラーバジェットをめぐる議論や、突然のデプロイ停止に悩まされることはもうありません。
          </span>
          <div>
            <strong>実現されること：</strong>
            <ul className="mt-4">
              <li>パイプラインガバナンスコラボレーション</li>
              <li>組織全体の透明性</li>
              <li>速度と信頼性のバランス</li>
            </ul>
          </div>
        </div>
      </>
    ),
  },
];

export const autoErrorTrackingFeatureCards = [
  {
    id: 1,
    title: "実稼働前に全てのエラーを検出・修正",
    summary: "新しい例外や重要な実行時例外の発生範囲を限定します。",
    icon: (
      <img
        src={icon1}
        loading="lazy"
        width={80}
        alt="Auto Error Tracking Feature Icon 1"
      />
    ),
    isSVG: true,
    height: "auto",
  },
  {
    id: 2,
    title: "より高品質なソフトウェアのデプロイ",
    summary: "継続的な品質改善により、納品速度と信頼性が向上します。",
    icon: (
      <img
        src={icon2}
        loading="lazy"
        width={80}
        alt="Auto Error Tracking Feature Icon 2"
      />
    ),
    isSVG: true,
    height: "auto",
  },
  {
    id: 3,
    title: "速度と信頼性の両方を向上",
    summary:
      "信頼性チェックにより、チームは自信を持ってより速くデリバリーできるようになります。",
    icon: (
      <img
        src={icon3}
        loading="lazy"
        width={80}
        alt="Auto Error Tracking Feature Icon 3"
      />
    ),
    isSVG: true,
    height: "auto",
  },
];

export const whySRMCards = [
  {
    id: 1,
    title: "パイプラインガバナンスの自動化",
    paragraph:
      "Harnessは、信頼性ガードレールと観測データを使用して、デプロイを続行すべきかどうかを判断します。",
    icon: <img src={icon4} loading="lazy" height={80} alt="Why SRM Icon 2" />,
    isSVG: true,
    height: "auto",
  },
  {
    id: 2,
    title: "継続的な信頼性向上",
    paragraph:
      "開発の全段階においてサービスの信頼性チェックを行い、デプロイ後の高い信頼性を実現します。",
    icon: <img src={icon5} loading="lazy" height={80} alt="Why SRM Icon 2" />,
    isSVG: true,
    height: "auto",
  },
  {
    id: 3,
    title: "ネイティブエラーの追跡",
    paragraph:
      "詳細なデバッグデータを使用して、あらゆるコード例外を迅速に特定および修正し、自信を持ってより速くデプロイできます。",
    icon: <img src={icon6} loading="lazy" height={80} alt="Why SRM Icon 3" />,
    isSVG: true,
    height: "auto",
  },
  {
    id: 4,
    title: "自動化されたSLOの追跡",
    paragraph:
      "SLOとエラーバジェットをスプレッドシートで手動追跡する必要はありません。Harnessが追跡し、グラフ化します。",
    icon: <img src={icon7} loading="lazy" height={80} alt="Why SRM Icon 4" />,
    isSVG: true,
    height: "auto",
  },
  {
    id: 5,
    title: "CI/CDとのインテグレーション",
    paragraph:
      "CI/CDパイプラインに信頼性ガードレールを組み込んで実施。速度と信頼性のバランスが実現されます。",
    icon: <img src={icon8} loading="lazy" height={80} alt="Why SRM Icon 5" />,
    isSVG: true,
    height: "auto",
  },
  {
    id: 6,
    title: "信頼性ビューの共有",
    paragraph:
      "信頼性とデプロイのステータスに関する継続的に更新されるインサイトが、全ての関連メンバーに提供されます。",
    icon: <img src={icon9} loading="lazy" height={80} alt="Why SRM Icon 6" />,
    isSVG: true,
    height: "auto",
  },
];

export const gridCardsData = [
  {
    id: 1,
    title: "モニタリングとオブザバビリティーの統合を設定",
    summary:
      "Harnessは、ソフトウェアの信頼性を判断するために、観測可能なデータにAI/MLを適用します。",
    icon: (
      <img
        src={card1}
        loading="lazy"
        alt="Grid Card Illustration 1"
        width={250}
        height={209}
      />
    ),
    isSVG: true,
    height: "auto",
  },
  {
    id: 2,
    title: "SLI、SLO、およびエラーバジェットを共同で定義",
    summary:
      "開発と信頼性の関係を進化させ、両チームが調和して信頼性の高いソフトウェアをより速くデリバリーできるようになります。",
    icon: (
      <img
        src={card2}
        loading="lazy"
        alt="Grid Card Illustration 2"
        width={250}
        height={209}
      />
    ),
    isSVG: true,
    height: "auto",
  },
  {
    id: 3,
    title: "パイプラインテンプレートへの信頼性ガードレールの追加",
    summary: "組織が信頼性機能を拡張するために必要な一貫性を提供します。",
    icon: (
      <img
        src={card3}
        loading="lazy"
        alt="Grid Card Illustration 3"
        width={250}
        height={209}
      />
    ),
    isSVG: true,
    height: "auto",
  },
  {
    id: 4,
    title: "CI/CDパイプラインの信頼性チェック",
    summary:
      "チームが信頼性の高いソフトウェアをより速くデリバリーできるようにします。",
    icon: (
      <img
        src={card4}
        loading="lazy"
        alt="Grid Card Illustration 4"
        width={250}
        height={209}
      />
    ),
    isSVG: true,
    height: "auto",
  },
  {
    id: 5,
    title: "信頼性の問題を迅速に修正",
    summary:
      "Harness Service Reliability Managementが提供する情報により、信頼性に影響を与えた問題を即座に把握し、迅速に修正できます。",
    icon: (
      <img
        src={card5}
        loading="lazy"
        alt="Grid Card Illustration 5"
        width={250}
        height={209}
      />
    ),
    isSVG: true,
    height: "auto",
  },
];
