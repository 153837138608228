import React, { useState } from "react";
import Container from "@layout/container";
import HeroBanner from "@components/common/heroBanner";
import VideoLightBox from "@components/common/videoLightBox/VideoLightBox";
import Meta from "@components/seo/Meta";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import GridCards from "@components/common/gridCards/GridCards";
import {
  whySRMCards,
  gridCardsData,
  autoErrorTrackingFeatureCards,
} from "@components/service-reliability-management/contents";
import ThreeColumnsGridCards from "@components/common/gridCards/ThreeColumnsGridCards";
import Features from "@components/service-reliability-management/Features/Features";

const ServiceReliabilityManagement = (props) => {
  const [isVideoPlaying, toggleVideoPlaying] = useState(false);
  const siteUrl = props?.data?.allSite?.nodes[0].siteMetadata?.siteUrl;
  const metaData = {
    title: `Service Reliability Management｜Harness正規販売代理店 DXable`,
    description: `Harness Service Reliability Managementは、パイプラインの適切な段階で適切なチェックを自動的に実行し、ソフトウェアデリバリーを高速化し信頼性を高めます。`,
    url: `${siteUrl}${props.path}`,
  };
  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <HeroBanner
          title={<BannerTitle />}
          paragraph={<BannerParagraph />}
          media={
            <MediaVideo
              isVideoPlaying={isVideoPlaying}
              toggleVideoPlaying={toggleVideoPlaying}
            />
          }
        />
        <Features />
        <ThreeColumnsGridCards
          title="あらゆるエラーの発見と修正を迅速に"
          description={
            <p>
              ネイティブエラートラッキングにより、開発の全段階における全てのエラーを自動的に特定し、完全なデバッグの詳細を提供します。
              <br />
              実行エラーは二度と本番環境に持ち込まれないようになります。
            </p>
          }
          cards={autoErrorTrackingFeatureCards}
          bg="#fff"
        />
        <GridCards
          title={"Harness Service Reliability Managementを活用する理由"}
          cards={whySRMCards}
          bg="#fff"
        />
        <ThreeColumnsGridCards
          title="Harness Service Reliability Managementの仕組み"
          cards={gridCardsData}
          bg="#fff"
        />
      </Container>
    </>
  );
};

const BannerTitle = () => {
  return <span>Harness Service Reliability Management</span>;
};

const BannerParagraph = () => {
  return (
    <>
      <span className="mb-4">より優れた信頼性、より速いデプロイメント。</span>
      <span>
        デプロイのスピードと本番環境並みの信頼性の二者択一は必要ありません。
      </span>
    </>
  );
};

const MediaVideo = ({ isVideoPlaying, toggleVideoPlaying }) => {
  return (
    <>
      <button onClick={() => toggleVideoPlaying(!isVideoPlaying)}>
        <StaticImage
          src="../../images/service-reliability-management/ServiceReliabilityManagementBanner.svg"
          layout="fullWidth"
          loading="eager"
          alt="Service Reliability Management Page Panner"
          className="w-full hover:cursor-pointer"
          quality={100}
        />
      </button>
      {isVideoPlaying && (
        <VideoLightBox
          src={"https://www.youtube.com/embed/_yA0PcZo0wM"}
          title={"Service Reliability Management in 5 mins"}
          toggleVideoPlaying={toggleVideoPlaying}
          isVideoPlaying={isVideoPlaying}
        />
      )}
    </>
  );
};

export const ServiceReliabilityManagementPageData = graphql`
  query fetchServiceReliabilityManagementPageData {
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;

export default ServiceReliabilityManagement;
